<template>
  <div class="country-checkbox">
    <div class="search-warpper">
      <el-input
        size="mini"
        v-model="form.twoCharCode"
        placeholder="输入国家/地区名称、二字码"
        style="width: 300px"
        clearable
        @change="init"
        @clear="init"
      >
        <i class="el-icon-search" style="line-height: 30px" slot="suffix" @click="init"> </i>
      </el-input>
    </div>
    <div class="continent-warpper">
      <span @click="form = {};init()" :class="[{ active: !form.continentDictCode }, 'all']">全部</span>
      <ul
        v-for="(state, index) in continentDictCode"
        :key="index"
        class="continent-whole"
        @click="form.continentDictCode = state.id;init()"
        :class="{ active: form.continentDictCode === state.id }"
      >
        <li>{{ state.itemName }}</li>
      </ul>
    </div>
    <div class="continent-select-warpper" v-loading="loading">
      <el-checkbox
        v-if="data.length"
        class="all"
        v-model="checkedAll"
        :indeterminate="isIndeterminate"
        @change="oninput"
      >全部</el-checkbox
      >
      <span v-if="!data.length">暂无数据</span>
      <el-checkbox-group v-model="checkedIdList">
        <el-checkbox
          v-for="(country, index) in data"
          :key="index"
          :label="country.value"
          :disabled="disabledIdList.includes(country.value)"
          @change="onchecked(country, $event)"
        >{{ country.label }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import checkMixin from '@/mixins/checkMixin'
import { getCounrty } from '@/api/country'
import { mapGetters } from 'vuex'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  mixins: [checkMixin],
  model: {
    prop: 'checkedList'
  },
  props: {
    checkedList: {
      type: Array,
      default: () => []
    },
    disabledIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        twoCharCode: '',
        continentDictCode: ''
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['continentDictCode'])
  },
  watch: {
    checkedList: {
      handler(n) {
        this.checkedAllIdList = this.map(n, 'value')
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      this.loading = true
      let res = await awaitResolve(
        getCounrty({
          ...this.handleSearchForm(),
          isDeleted: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      this.loading = false
      if (!res) return

      this.data = this.handleData(res.detail || [])
    },
    handleSearchForm() {
      let { form } = this
      let query = {}
      for (const formKey in form) {
        if (!validatenull(form[formKey])) {
          query[formKey] = form[formKey]
        }
      }
      return query
    },
    handleData(arr) {
      return arr.map(item => {
        this.$set(item, 'label', item.countryCnName)
        this.$set(item, 'value', item.id)
        return item
      })
    },

    onchecked(item, checked) {
      this.handleChecked(item, checked)

      this.oninput()
    },

    oninput() {
      // console.log('oninput')
      this.$emit('input', this.checkedAllList)
    }
  }
}
</script>

<style lang="scss" scoped>
.country-checkbox ::v-deep {
  width: 527px;
  border-right: 1px solid #dcdee0;
  border-bottom: 1px solid #dcdee0;
  .continent-warpper {
    height: 48px;
    align-items: center;
    border-bottom: 1px solid #dcdee0;
    display: flex;
    .active {
      color: #fff !important;
      background: #3841db !important;
      border: 1px solid #3841db !important;
      border-radius: 2px !important;
    }
    .all {
      margin-left: 16px;
    }
    .all,
    .continent-whole {
      padding: 9px;
      border: 1px solid #dcdee0;
      border-radius: 2px;
      margin-right: 12px;
      background: #f5f5f5;
      height: 32px;
      cursor: pointer;
      color: #969799;
      li {
        list-style: none;
      }
    }
  }
  .continent-select-warpper {
    padding: 16px 0 0 16px;
    height: 390px;
    overflow: auto;
    .all {
      .el-checkbox__label {
        padding-right: 25px;
      }
    }
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border: 1px solid #c8c9cc;
      border-radius: 2px;
    }
    .el-checkbox-group {
      display: inline;
    }
    .el-checkbox__label {
      padding-left: 5px;
    }
    .el-checkbox {
      color: #595961;
      font-weight: normal;
      margin-bottom: 13px;
    }
  }
  .search-warpper {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #dcdee0;
    padding-left: 16px;
  }
}
</style>
