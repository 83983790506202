/* eslint-disable */
import { pull, map, uniq, uniqBy } from 'lodash'

export default {
  data() {
    return {
      data: [],
      checkedIdList: [],
      isIndeterminate: false,
      checkedAllIdList: []
    }
  },
  computed: {
    checkedAll: {
      get() {
        let total = this.data.length
        let checkedCount = this.checkedIdList.length
        this.isIndeterminate = checkedCount > 0 && checkedCount < total
        return checkedCount === total
      },
      set(checked) {
        this.handleCheckedAll(checked)
      }
    },
    
    idList() {
      return map(this.data, 'value')
    },
    checkableIdList({ disabledIdList }) {
      return Array.isArray(disabledIdList)
        ? this.idList.filter(id => !disabledIdList.includes(id))
        : this.idList
    },
    checkedAllList({ checkedList, checkedAllIdList, data }) {
      data = uniqBy(checkedList.concat(data), 'value')
      return data.filter(item => checkedAllIdList.includes(item.value))
    }
  },
  created() {
    if (this.unwatch) this.unwatch()
    this.unwatch = this.$watch(
      function () {
        return {
          checkedAllIdList: this.checkedAllIdList,
          idList: this.idList
        }
      },
      function (n) {
        // 使用 idList 是因为 disabledIdList 也是可以被选中的
        let { idList, checkedAllIdList } = n
        this.checkedIdList = [...idList.filter(id => checkedAllIdList.includes(id))]
      },
      {
        immediate: true,
        deep: true
      }
    )
  },
  methods: {
    map,
    handleCheckedAll(checked, checkableIdList = this.checkableIdList) {
      if (checked) {
        this.checkedAllIdList = uniq(this.checkedAllIdList.concat(checkableIdList))
      } else {
        pull(this.checkedAllIdList, ...checkableIdList)
      }
    },
    handleChecked(item, checked) {
      if (checked) {
        this.checkedAllIdList.push(item.value)
        this.checkedAllIdList = uniq(this.checkedAllIdList)
      } else {
        pull(this.checkedAllIdList, item.value)
      }
    }
  }
}